<template>
  <div class="preview-template">
    <div class="canvas">
      <div class="artboard" data-id="" />
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  computed: {
    ...mapGetters("templates", {
      template: "getSingleTemplate"
    }),
    ...mapGetters("teams", {
      team: "getQuestionedTeam",
      parentBrand: "getParentBrand",
      generalAssets: "getGeneralAssets"
    }),
    ...mapGetters("user", {
      user: "getUserProfile"
    }),
    videoPlaceholder() {
      return require("../assets/img/video-placeholder.png");
    },
    imagePlaceholder() {
      return require("../assets/img/image-placeholder.png");
    },
    brand() {
      if (this.parentBrand) {
        return this.parentBrand;
      } else {
        return this.team.brand ? this.team.brand : {};
      }
    },
    textStyles() {
      return this.brand?.textStyles;
    }
  },
  mounted() {
    if (this.$route.params.teamId && this.$route.params.templateId) {
      this.fetchTeam(this.$route.params.teamId).then(() => {
        if (this.team.parentTeam) {
          this.fetchParentTeam();
        }
        this.fetchGeneralAssets().then(() => {
          this.setFonts();
          this.fetchSingleTemplate({
            teamId: this.$route.params.teamId,
            templateId: this.$route.params.templateId
          }).then(() => {
            this.drawCanvas();
          });
        });
      });
    }
  },
  methods: {
    ...mapActions("templates", ["fetchSingleTemplate"]),
    ...mapActions("teams", [
      "fetchTeam",
      "fetchGeneralAssets",
      "fetchParentTeam"
    ]),
    ...mapActions("user", ["fetchUserProfile"]),
    setFonts() {
      if (Object.keys(this.textStyles).length > 0) {
        let textStyles = Object.entries(this.textStyles).map(
          ([key, value]) => ({
            key,
            ...value
          })
        );
        let cssStyles = document.createElement("style");
        let newStyle = document.createElement("style");
        let fonts = {};

        if (this.brand && this.brand.fonts) {
          fonts = {
            ...this.generalAssets.brand.fonts,
            ...this.brand.fonts
          };
        } else {
          fonts = {
            ...this.generalAssets.brand.fonts
          };
        }

        textStyles.forEach(font => {
          cssStyles.appendChild(
            document.createTextNode(
              ".canvas *[data-class='" +
                font.key +
                "']{font-family:" +
                font.fontFamily +
                "; font-weight:" +
                font.fontWeight +
                "; font-size:" +
                font.fontSize +
                "px;}"
            )
          );

          newStyle.appendChild(
            document.createTextNode(
              "\
              @font-face {\
                  font-family: " +
                font.fontFamily +
                ";\
                  src: url('" +
                fonts[font.fontId].url +
                "')\
                  format('woff');\
              }\
              "
            )
          );
        });

        document.body.prepend(cssStyles);
        document.head.appendChild(newStyle);
      }
    },
    drawCanvas() {
      Object.assign(
        document.getElementsByClassName("artboard")[0].style,
        this.template.css[0]
      );
      this.drawChildren(this.template.elements, ".artboard");
    },
    drawChildren(elements, parent) {
      for (var key in elements) {
        var element = elements[key];
        var text = "";
        if (element.text) {
          text = "<span>" + element.text + "</span>";
        }
        var classes = "";

        for (var classKey in element.classes) {
          var className = element.classes[classKey];
          if (className != null) {
            classes += className;
          }
        }
        var image = "";
        if (element.image != undefined) {
          if (element.image.src.includes("img/image-placeholder.svg")) {
            image = "<img src='" + this.imagePlaceholder + "'></img>";
          } else {
            image = "<img src='" + element.image.src + "'>";
          }
        }

        if (element.video != undefined) {
          var poster = "";
          if (element.poster != undefined) {
            poster = " poster=" + element.poster;
          }

          if (element.video.src.includes("img/video-placeholder.svg")) {
            image = "<video poster='" + this.videoPlaceholder + "'></video>";
          } else {
            image =
              "<video src='" + element.video.src + "' " + poster + "></video>";
          }
        }

        document
          .querySelector(parent)
          .insertAdjacentHTML(
            "beforeend",
            "<" +
              element.tag +
              " data-class='" +
              classes +
              "' data-index=" +
              key +
              " data-id=" +
              element.id +
              " data-type='" +
              element.type +
              "'" +
              poster +
              ">" +
              text +
              image +
              "</" +
              element.type +
              ">"
          );
        //document.querySelector(parent).querySelectorAll("*[data-id=" + element.id + "]")[0].css(element.css)
        Object.assign(
          document
            .querySelector(parent)
            .querySelectorAll("*[data-id='" + element.id + "']")[0].style,
          element.css[0]
        );

        this.drawChildren(
          element.children,
          parent + " *[data-id='" + element.id + "']"
        );
      }
    },
    loadBrand() {
      var cssStyles = "<style>";

      for (var key in this.brand.textStyles) {
        var font = this.brand.textStyles[key];
        cssStyles +=
          ".artboard *[data-class='" +
          key +
          "']{font-family:" +
          font.fontFamily +
          "; font-weight:" +
          font.fontWeight +
          "; font-size:" +
          font.fontSize +
          ";}";
      }

      cssStyles += "</style>";

      document
        .querySelector("body")
        .insertAdjacentHTML("afterbegin", cssStyles);
    }
  }
};
</script>

<style lang="scss">
.preview-template .artboard * {
  margin: auto;
}

.preview-template {
  .artboard {
    position: absolute !important;
    transform: scale(1) !important;
    top: 0;
    left: 0;
    overflow: hidden !important;

    svg {
      position: absolute;
      height: 100%;
      width: 100%;
      left: 0;
      top: 0;
    }
  }

  text {
    margin: unset !important;

    span {
      white-space: pre-line;
    }
  }

  imagecontainer img,
  videocontainer video {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }
  imagecontainer[data-class="contain"] img,
  videocontainer[data-class="contain"] video {
    object-fit: contain !important;
  }
  imagecontainer[data-class="cover"] img,
  videocontainer[data-class="cover"] video {
    object-fit: cover !important;
  }
}
</style>
